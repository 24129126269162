import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/es6-generators/",
  "date": "2016-08-11",
  "title": "ES6 GENERATORS",
  "author": "admin",
  "tags": ["development", "javascript", "es6"],
  "featuredImage": "feature.jpg",
  "excerpt": "ES6 generators are functions that can be paused or resumed, and work cooperatively in a multitasking applications."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`ES6 generators become useful in the scenarios listed below:`}</p>
    <ul>
      <li parentName="ul">{`Asynchronous function calls`}</li>
      <li parentName="ul">{`Iterables`}</li>
    </ul>
    <h2>{`Cooperative`}</h2>
    <p>{`Generator function, itself chooses when it will allow an interruption – corporate with other code.`}</p>
    <p>{`Between Pause and start of a generator, other code can be run.`}</p>
    <h2>{`Stop Start`}</h2>
    <p>{`Generators can add pause-resume kind of capability to your application to avoid blocking calls.`}</p>
    <Message type="info" title="yield" content="Pauses the function – nothing can pause a generator from outside." mdxType="Message" />
    <p>{`Once yield-paused, a generator can’t resume by itself. External control needed to resume.`}</p>
    <h2>{`Infinite Loops`}</h2>
    <p>{`Generators also use infinite loops to keep it listening for changes, which never finishes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`while(true) { .. }
`}</code></pre>
    <p>{`Even though it’s a bad practise in other scenarios, `}<inlineCode parentName="p">{`while`}</inlineCode>{` loop is ideal for generators.`}</p>
    <h2>{`Parameters`}</h2>
    <p>{`With normal functions, you get parameters at the beginning and a return value at the end.`}</p>
    <p>{`With generator functions, you send messages out with each yield, and you send messages back in with each restart.`}</p>
    <p>{`Pause-resume helps 2-way message passing (in and out of the generator).`}</p>
    <ul>
      <li parentName="ul">{`Normal functions – get parameters at the beginning, return a value at the end`}</li>
      <li parentName="ul">{`Generator functions – send messages out with yield, get messages with each restart`}</li>
    </ul>
    <p>{`Yield expression – when we restart the generator we send a value back in, and that value will be the computed result of that yield.`}</p>
    <h2>{`Syntax`}</h2>
    <p>{`Here is a simple example on how to use generators:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function *foo() {
    yield "bar"; // highlight
}
 
const iter = foo();
const result = iter.next();
console.log(result);  // {"value":"bar","done":false}
`}</code></pre>
    <p>{`Line 2: At pause, `}<inlineCode parentName="p">{`bar`}</inlineCode>{` is sent out.`}</p>
    <p>{`Line 6: The generator restarts and gives the value back`}</p>
    <p>{`Line 7: Print out the result `}<inlineCode parentName="p">{`{“value”: ”bar”, ”done”: false}`}</inlineCode></p>
    <p>{`Let’s improve on it a bit:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function *foo() {
    const foobar = yield 'bar';
    console.log(footer);  // foo
}
 
const iter = foo();
console.log(iter.next());
// the generator restarts and gives a value back
console.log(iter.next('foo'));
`}</code></pre>
    <p>{`Line 3: Prints out `}<inlineCode parentName="p">{`foo`}</inlineCode>{`.`}</p>
    <p>{`Line 6: Prints out `}<inlineCode parentName="p">{`{“value”: ”bar”, ”done”: false}`}</inlineCode></p>
    <p>{`Line 7: Print out the result {“done”:true}`}</p>
    <p>{`When you run this code snippet, you will get the following results:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`{“value”:”bar”,”done”:false}
foo 
{“done”:true}
`}</code></pre>
    <Message type="info" title="Babel REPL" content="In order to run the examples, you need to use the [Babel REPL](https://babeljs.io/repl/)." mdxType="Message" />
    <h2>{`Conclusion`}</h2>
    <p>{`ES6 generators can be difficult to grasp first. But once you start using them, you will quickly realise the benefits surrounding it.`}</p>
    <p>{`If you want to learn more about generators, `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Statements/function*"
      }}>{`MDN`}</a>{` is a great place to start.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      